<template>
  <nav class="navbar min-w-1400">
    <div class="secondBox max-w-1400">
      <div class="logo">
        <!-- <img
          :src="require('@/page/ceibamóvil/components/img/logo.png')"
          :alt="logo"
          class="logoImg"
        /> -->
        <span class="title">DEBUT WONLAND COMPANY LIMITED</span>
      </div>
      <ul class="nav-links">
        <li
          class="dropdown Li1"
          :class="{
            'active-link':
              $route.path === '/about' || $route.path === '/mission'|| $route.path === '/develop'  
             
          }"
        >
          <!-- 添加下拉图标 -->
          <router-link to="/" class="nav-link2">
            <span class="dropdown-toggle routeText1">Về Chúng Tôi</span>
          </router-link>
          <ul class="dropdown-menu" ref="dropdownMenu">
            <li
              :class="{
                'active-link1': $route.path === '/about',
              }"
            >
              <router-link to="/about" class="dropdown-toggle routeText5">
                Chúng Tôi Là Ai?
              </router-link>
            </li>
            <li
              :class="{
                'active-link1': $route.path === '/mission',
              }"
            >
              <router-link to="/mission" class="dropdown-toggle routeText6">
                Tầm Nhìn - Sứ Mệnh
              </router-link>
            </li>
            <li
              :class="{
                'active-link1': $route.path === '/develop',
              }"
            >
              <router-link to="/develop" class="dropdown-toggle routeText7">
                Phát Triển Bền Vững
              </router-link>
            </li>
          </ul>
        </li>
        <li :class="{ 'active-link': $route.path === '/service' }" class="Li2">
          <router-link to="/service" class="nav-link2">
            <span class="routeText2">Về khoản vay</span>
          </router-link>
        </li>
        <li
          :class="{ 'active-link': $route.path === '/questions' }"
          class="Li3"
        >
          <router-link to="/questions" class="nav-link3">
            <span class="routeText3">Trung tâm trợ giúp</span>
          </router-link>
        </li>
        <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li4">
          <router-link to="/protocol" class="nav-link4">
            <span class="routeText4">Chính sách bảo mật</span>
          </router-link>
        </li>
        <!-- <li
          :class="{
            'active-link': ['/questions', '/protocol'].includes($route.path),
          }"
          class="Li4"
        >
          <router-link to="/questions" class="nav-link4">
            <span class="routeText4">Vấn đề Thường Gặp</span>
          </router-link>
        </li> -->
        <!-- <li :class="{ 'active-link': $route.path === '/protocol' }" class="Li5">
          <router-link to="/protocol" class="nav-link5">
            <span class="routeText5">Acuerdo de privacidad</span>
          </router-link>
        </li> -->
      </ul>
    </div>
    <div style="height: 130px"></div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      link1Color: "white",
    };
  },
  methods: {
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  position: sticky; /* 将导航栏固定在页面上 */
  top: 0; /* 位于页面顶部 */
  z-index: 1000; /* 确保导航栏在其他元素上方 */
  height: 80px;
}
.secondBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  height: 80px;
  padding: 0 30px;
  background-color: #ffffff;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
}

.logoImg {
  width: 60px;
  height: 60px;
}

.title {
  width: 520px;
  height: 24px;
  font-size: 18px;
  font-family: SF Pro-Black Italic;
  font-weight: 600;
  color: #3742aa;
  line-height: 23px;
  padding-left: 17px;
  /* text-transform: uppercase; */
}

.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5,
.routeText6,
.routeText7 {
  font-size: 16px;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #666666;
  line-height: 29px;
  /* text-transform: uppercase; */
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
  margin: 0 auto;
  text-align: center;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  /* 垂直居中对齐 */
  justify-content: center;
  /* 水平居中对齐 */
}

.nav-links li {
  display: flex;
  padding: 8px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 45px;
  padding-left: 4px;
  padding-right: 4px;
}
.Li1 {
  width: 170px;
}
.Li2 {
  width: 150px;
}
.Li3 {
  width: 200px;
}
.Li4 {
  width: 230px;
}
/* .Li5 {
  width: 250px;
} */
/* 这个如果给margin-right底部可能会出现边距的问题 */
/* .Li4 {
  width: 250px;
  
} */

.active-link {
  /* background: linear-gradient(45deg, #F59120, #F95C40); */
  /* background-color: #0040B2; */
  /* border-color: #dae633; */
  /* border-radius: 10px; */
  /* color: #3742aa; */
}

.active-link .routeText1,
.active-link .routeText2,
.active-link .routeText3,
.active-link .routeText4
 {
  font-family: Inter-Extra Bold;

  color: #3742aa;
}
.active-link1 .routeText5,
.active-link1 .routeText6,
.active-link1 .routeText7

 {
  font-family: Inter-Extra Bold;

  color: #3742aa;

}
.active-link1 
 {
    border-bottom: 1px solid #3742aa;
}
.dropdown {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid rgb(204, 204, 204);
  padding: 5px 0;
  min-width: 100px;
}

.dropdown-menu li {
  padding: 5px 10px;
}
/* .dropdown-menu li:hover {
  padding: 5px 10px;
  border-bottom: 1px solid #3742aa;
} */
.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown:hover .dropdown-menu {
  display: block;
}
.dropdown-menu li a {
  font-size: 13px;
  text-decoration: none;
  font-family: SF Pro-Semibold;
  font-weight: 550;
  color: #000000;
  margin-top: 10px;
}
.dropdown-icon {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000; /* 设置倒三角形的颜色 */
  border-bottom: none; /* 去除下边框 */
  margin-left: 5px;
  vertical-align: middle;
}
/* .dropdown-menu li a:hover {
  color: #3742aa;
} */


</style>