var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar min-w-1400"},[_c('div',{staticClass:"secondBox max-w-1400"},[_vm._m(0),_c('ul',{staticClass:"nav-links"},[_c('li',{staticClass:"dropdown Li1",class:{
          'active-link':
            _vm.$route.path === '/about' || _vm.$route.path === '/mission'|| _vm.$route.path === '/develop'  
           
        }},[_c('router-link',{staticClass:"nav-link2",attrs:{"to":"/"}},[_c('span',{staticClass:"dropdown-toggle routeText1"},[_vm._v("Về Chúng Tôi")])]),_c('ul',{ref:"dropdownMenu",staticClass:"dropdown-menu"},[_c('li',{class:{
              'active-link1': _vm.$route.path === '/about',
            }},[_c('router-link',{staticClass:"dropdown-toggle routeText5",attrs:{"to":"/about"}},[_vm._v(" Chúng Tôi Là Ai? ")])],1),_c('li',{class:{
              'active-link1': _vm.$route.path === '/mission',
            }},[_c('router-link',{staticClass:"dropdown-toggle routeText6",attrs:{"to":"/mission"}},[_vm._v(" Tầm Nhìn - Sứ Mệnh ")])],1),_c('li',{class:{
              'active-link1': _vm.$route.path === '/develop',
            }},[_c('router-link',{staticClass:"dropdown-toggle routeText7",attrs:{"to":"/develop"}},[_vm._v(" Phát Triển Bền Vững ")])],1)])],1),_c('li',{staticClass:"Li2",class:{ 'active-link': _vm.$route.path === '/service' }},[_c('router-link',{staticClass:"nav-link2",attrs:{"to":"/service"}},[_c('span',{staticClass:"routeText2"},[_vm._v("Về khoản vay")])])],1),_c('li',{staticClass:"Li3",class:{ 'active-link': _vm.$route.path === '/questions' }},[_c('router-link',{staticClass:"nav-link3",attrs:{"to":"/questions"}},[_c('span',{staticClass:"routeText3"},[_vm._v("Trung tâm trợ giúp")])])],1),_c('li',{staticClass:"Li4",class:{ 'active-link': _vm.$route.path === '/protocol' }},[_c('router-link',{staticClass:"nav-link4",attrs:{"to":"/protocol"}},[_c('span',{staticClass:"routeText4"},[_vm._v("Chính sách bảo mật")])])],1)])]),_c('div',{staticStyle:{"height":"130px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('span',{staticClass:"title"},[_vm._v("DEBUT WONLAND COMPANY LIMITED")])])
}]

export { render, staticRenderFns }